import { createApp } from "vue";
import { createPinia } from "pinia";
import { createVfm } from "vue-final-modal";
import Vue3Toastify, { toast, type ToastContainerOptions } from "vue3-toastify";
import VueGoogleMaps from "vue-google-maps-community-fork";
import Vue3EasyDataTable from "vue3-easy-data-table";
import { createHead } from "@unhead/vue";
import App from "@/App.vue";
import i18n from "@/i18n";
import router from "@/router";
import icons from "@/icons";
import cookieConsent from "@/cookie-consent";
import { langToString, tByLang, tIsDe } from "@/components/helpers/i18n";

import "@/style/global.scss";
import "vue-final-modal/style.css";
import "vue3-toastify/dist/index.css";
import "vue3-easy-data-table/dist/style.css";
import { SFacebook, SLinkedIn, STwitter, SWhatsApp, SXing } from "vue-socials";

const mapsApiKey = import.meta.env.VITE_MAPS_API_KEY as string | undefined;
if (typeof mapsApiKey === "undefined") {
  alert("VITE_MAPS_API_KEY is missing in .env!");
}

const app = createApp(App);

app.config.globalProperties.$tByLang = tByLang;
app.config.globalProperties.$tIsDe = tIsDe;
app.config.globalProperties.$langToString = langToString;

app
  .use(i18n)
  .use(createPinia())
  .use(router)
  .use(createVfm())
  .use(VueGoogleMaps, {
    load: {
      key: mapsApiKey,
    },
  })
  .use(Vue3Toastify, {
    hideProgressBar: true,
    autoClose: 5000,
    position: toast.POSITION.BOTTOM_CENTER,
    transition: toast.TRANSITIONS.SLIDE,
    theme: toast.THEME.COLORED,
  } as ToastContainerOptions)
  .use(icons)
  .use(cookieConsent)
  .use(createHead())
  .component("EasyDataTable", Vue3EasyDataTable)
  .component("SFacebook", SFacebook)
  .component("SLinkedIn", SLinkedIn)
  .component("STwitter", STwitter)
  .component("SXing", SXing)
  .component("SWhatsApp", SWhatsApp)
  .mount("#app");
