import { toast, type ToastOptions } from "vue3-toastify";
import i18n from "@/i18n";

const options = {
  authCommon: { toastId: "auth", multiple: false },
  error: { multiple: false },
} as Record<string, ToastOptions>;

export default {
  authTokenExpired: () =>
    toast.error(i18n.global.t("token-expired"), options.authCommon),
  signedIn: () => toast.success(i18n.global.t("login-successful")),
  signedOut: () => toast.info(i18n.global.t("signed-out")),
  onlyGuestsAllowed: () =>
    toast.warning(i18n.global.t("only-guests-allowed"), options.authCommon),
  authenticationRequired: () =>
    toast.warning(i18n.global.t("authentication-required"), options.authCommon),
  accessDenied: () =>
    toast.warning(i18n.global.t("access-denied"), options.authCommon),
  passwordChanged: () => toast.success(i18n.global.t("password-changed")),
  passwordSet: () => toast.success(i18n.global.t("password-set")),
  passwordsMustMatch: () => toast.error(i18n.global.t("passwords-must-match")),

  entryTitleUpdated: () => toast.success(i18n.global.t("entry-title-updated")),
  entryAddressUpdated: () =>
    toast.success(i18n.global.t("entry-address-updated")),
  entryRepresentativeUpdated: () =>
    toast.success(i18n.global.t("entry-representative-updated")),
  entrySocialMediaUpdated: () =>
    toast.success(i18n.global.t("entry-social-media-updated")),
  entryTextUpdated: () => toast.success(i18n.global.t("entry-text-updated")),
  entryStatsUpdated: () => toast.success(i18n.global.t("entry-stats-updated")),
  entryDiscarded: () => toast.info(i18n.global.t("entry-discarded")),
  entryDraftCreated: () => toast.success(i18n.global.t("entry-draft-created")),
  entrySubmittedForReview: () =>
    toast.success(i18n.global.t("entry-submitted-for-review")),
  entryLogoUpdated: () => toast.success(i18n.global.t("entry-logo-updated")),
  entryImagesUpdated: () =>
    toast.success(i18n.global.t("entry-images-updated")),
  entryMissionsUpdated: () =>
    toast.success(i18n.global.t("entry-missions-updated")),
  entryCertificatesUpdated: () =>
    toast.success(i18n.global.t("entry-certificates-updated")),
  entrySegmentsUpdated: () =>
    toast.success(i18n.global.t("entry-segments-updated")),
  registrationTokenInvalid: () =>
    toast.warning(i18n.global.t("registration-token-invalid")),
  entryOrgaTypeUpdated: () =>
    toast.success(i18n.global.t("entry-orga-type-updated")),
  entryTargetedCoopsUpdated: () =>
    toast.success(i18n.global.t("entry-targeted-coops-updated")),
  changesDiscarded: () => toast.info(i18n.global.t("changes-discarded")),
  mustChooseOrganisationType: () =>
    toast.warning(i18n.global.t("must-choose-organisation-type")),
  ambiguousAccount: () => toast.warning(i18n.global.t("ambiguous-account")),

  reviewSuccess: () => toast.success(i18n.global.t("review-success")),
  entryRequiresAttention: () =>
    toast.info(i18n.global.t("entry-requires-attention")),
  // reviewError: () => toast.warning(i18n.global.t("review-error")),
  acceptTerms: () => toast.warning(i18n.global.t("accept-terms")),
  downloadedCatalogue: () =>
    toast.success(i18n.global.t("downloaded-catalogue")),
  error: (key: string) => toast.error(i18n.global.t(key), options.error),
  registrationSuccess: () =>
    toast.success(i18n.global.t("registration-success")),
  entryLinkCopied: () => toast.success(i18n.global.t("entry-link-copied")),
};
